.App {
  box-sizing: border-box;
  min-width: 375px;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

* {
  box-sizing: border-box;
}

input[type="file"] {
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

label {
  display: inline-block;
  font-size: inherit;
  line-height: normal;
  vertical-align: middle;
  cursor: pointer;
  padding: 8px;
}

.slick-dots li.slick-active button:before {
  color: #ca0900 !important;
}

.slick-dots li button {
  color: white !important;
}

.slick-dots li button:before {
  color: white !important;
  opacity: 1;
}

.slick-prev:before,
.slick-next:before {
  opacity: 1;
}
